import React from "react";
import { Link } from "react-router-dom";
import style from "./header.module.css";
import NavbarMatrialAdmin from "./navbarMetrial";

const Header = () => {
  return (
    <div>
      <header className={style.header} >
        <NavbarMatrialAdmin  />
      </header>
    </div>
  );
};

export default Header;
