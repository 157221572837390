import React from 'react'
import { useNavigate } from 'react-router-dom';
import style from './auth.module.css';
import { FaEyeSlash, FaEye } from 'react-icons/fa'
import { useState } from 'react';
import { apiGet, apiPost } from '../../shared/services/services';
import { CHECK_SUBSCRIBES, CONNERCT_TO_ANOTHER_DEVICET, LOGIN_ROUTE, LOGIN_WITH_GOOGLE_ROUTE } from '../../shared/constant/url';
import { useEffect } from 'react';
import { TOKEN_KEY } from '../../shared/constant/string';
import { Button } from '@mui/material';
import useSimpleForm from '../../shared/hooks/useForm';
import { useSelector, useDispatch } from "react-redux";
import { getUser } from '../../shared/redux/features/userSlice';
import jwt_decode from "jwt-decode"
const Login = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((store) => store.userReducer);

    let errorsObj = {}
    const [form, setForm, errors, setErrors, resetForm] = useSimpleForm({ email: "", password: "" })
    const regEmail = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)
    const nav = useNavigate()
    const [eye, setEye] = useState('password')
    const valid = () => {
        if (!form.email) {
            errorsObj.email = "מייל לא יכול להיות ריק";
        }
        else if (!regEmail.test(form.email)) {
            errorsObj.email = "מייל לא תקין";
        }
        if (!form.password || form.password.length > 25 || form.password.length < 5) {
            errorsObj.password = "סיסמא אינה תקינה"
        }
        setErrors(errorsObj)
    }

    const onSub = (e) => {
        e.preventDefault();
        valid()
        if (errorsObj.email || errorsObj.password) {
            return
        }
        login(form)
    }



    const login = async (_dataBody) => {
        try {
            let connectedToAnotherDevice = await apiPost(CONNERCT_TO_ANOTHER_DEVICET, _dataBody);

            if (connectedToAnotherDevice.data.status == false) {
                const { data } = await apiPost(LOGIN_ROUTE, _dataBody)
                if (data.token) {
                    localStorage.setItem(TOKEN_KEY, data.token)
                    dispatch(getUser())
                    const resp = await apiGet(CHECK_SUBSCRIBES);
                    if (data?.role == "admin") {
                        nav('/admin')
                    } else {
                        nav('/')
                    }

                }
            } else {
                if (window.confirm("משתמש כבר זה מחובר ממכשיר אחר האם להתחבר?")) {
                    const { data } = await apiPost(LOGIN_ROUTE, _dataBody)
                    if (data.token) {
                        localStorage.setItem(TOKEN_KEY, data.token)
                        dispatch(getUser())
                        const resp = await apiGet(CHECK_SUBSCRIBES);
                        if (data?.role == "admin") {
                            nav('/admin')
                        } else {
                            nav('/')
                        }

                    }
                }
            }
        } catch (err) {
            if (err.response.data.wrongPassword) {
                setErrors({ ...errorsObj, resp: "סיסמא שגויה" })
            } else if (err.response.data.wrongEmail) {
                setErrors({ ...errorsObj, resp: "אימייל לא קיים במערכת" })
            }
        }
    }




    return (
        <div style={{ height: '80vh' }} className='justify-content-center pt-5 d-flex container'>


            <div dir='rtl' className='col-lg-5 col-md-8 col-12'>
                <h1 className='display-4 text-center pb-5'>התחברות</h1>
                <form className='px-4 p-md-0' onSubmit={onSub} >
                    <div>
                        <label>כתובת מייל</label>
                        <div>

                            <div className='col-12'></div>
                            <input value={form.email} onChange={e => setForm({ email: e.target.value })} type="text" className={`col-12 ${style.formCtrl} ${style.myShadow}`} />
                        </div>
                        {errors.email && <small className="text-danger d-block">{errors.email}</small>}
                    </div>

                    <div className='pt-3'>
                        <label>סיסמא</label>
                        <div className={`d-flex ${style.myShadow} ${style.formCtrl} ${style.formCtrlPassoword} align-items-center`}>
                            <input value={form.password} onChange={e => setForm({ password: e.target.value })} type={eye} className={`col-11 ${style.formPassword}`} />
                            {eye === 'password' ?
                                <FaEyeSlash style={{ cursor: 'pointer' }} onClick={() => setEye('text')} className='me-auto ms-2 ' fontSize={'20px'} /> :
                                <FaEye style={{ cursor: 'pointer' }} onClick={() => setEye('password')} className='me-auto ms-2' fontSize={'20px'} />
                            }
                        </div>
                        {errors.password && <small className="text-danger d-block">{errors.password}</small>}
                        {errors.resp && <small className="text-danger d-block">{errors.resp}</small>}

                        <p onClick={()=>nav('/forgotPassword')} className={`text-start pt-2 ${style.forgotPassword} me-auto col-4 col-md-3`}>שכחתי סיסמא</p>

                    </div>


                    <div className='justify-content-center d-flex pt-5'>

                        <Button type='submit' className={` ${style.btn} w-auto px-[60px]`} variant="contained" color="success" sx={{ width: '100%', fontSize: '1.1em' }}>התחבר</Button>

                    </div>

            
                </form>

            </div>



        </div>
    )
}

export default Login