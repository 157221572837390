import "./App.css";
import AppRoutes from "./shared/routes/appRoutes";
import { Provider, useDispatch } from "react-redux";
import myStore from "./shared/redux/store";
import { setIsCartOpen } from "./shared/redux/features/cartSlice";


function App() {

  return (
    <Provider store={myStore}>
      <AppRoutes />
    </Provider>
  );
}

export default App;
