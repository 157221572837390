import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from '../../components/home';
import Login from '../../components/auth/login';
import CreateCourse from '../../components/userCMS/courses/createCourse/createCourse';

import MyCourses from '../../components/userCMS/courses/myCourses/myCourses';
import ShowCourse from '../../components/userCMS/courses/myCourses/showCourse/showCourse';
import Logout from '../components/auth/logout';
import DisplayCourse from '../../components/userCMS/display/displayCourse';
import MyLearning from '../../components/userCMS/courses/myLearning/myLearning';
import MyAccount from '../../components/userCMS/myAccount/myAccount';
import NotFound from '../../components/page404/notFound';
import Layout from '../layout/layoutUser/layout';
import LayoutAdmin from '../layout/layoutAdmin/layoutAdmin';
import UsersManagement from '../../components/admin/manageUsers/usersManagement';
import ForgotPassword from '../../components/auth/forgotPassword';
import ResetPassword from '../../components/auth/resetPassword';

const AppRoutes = () => {
    return (
        <Router>
            <Routes>
                <Route path='/' element={<Layout />}>
                    <Route index element={<Home />} />
                    {/* UserAuth */}
                    <Route path='/login' element={<Login />} />
                    {/* <Route path='/signUp' element={<SignUp />} /> */}
                    <Route path='/forgotPassword' element={<ForgotPassword />} />
                    <Route path='/resetPassword' element={<ResetPassword />} />
                    <Route path='/logout' element={<Logout />} />
                    {/* ------------- */}

                    {/* UserCMS - >> Courses */}
                    <Route path='/myAccount' element={<MyAccount />} />
                    {/* <Route path='/coursePage' element={<CoursePage />} /> */}
                    <Route path='/course' element={<DisplayCourse />} />
                    {/* <Route path='/order/thankYou' element={<ThankYouOrder />} />
                    <Route path='/favourites' element={<Favourites />} /> */}
                    <Route path='/myLearning' element={<MyLearning />} />
                    {/* ------------- */}
                </Route>

                <Route path='/admin' element={<LayoutAdmin />}>
                    {/* Admin */}
                    <Route index element={<Home />} />
                    <Route path='/admin/createCourse' element={<CreateCourse />} />
                    <Route path='/admin/myCourses' element={<MyCourses />} />
                    <Route path='/admin/showCourse' element={<ShowCourse />} />
                    <Route path='/admin/management' element={<UsersManagement />} />
                    {/* ------------- */}
                </Route>
                <Route path='*' element={<NotFound />} />
            </Routes>
        </Router>
    )
}

export default AppRoutes