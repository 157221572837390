import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import AuthAdmin from '../../../shared/components/auth/authAdmin'
import { BuyCoursesForNewOrExistingUser } from '../../../shared/constant/url'
import { apiPost } from '../../../shared/services/services'
import style from './userManagements.module.css'
const UsersManagement = () => {
    const { register, getValues, handleSubmit, formState: { errors } } = useForm();


    const regEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    const nav = useNavigate();
    const [successMsg, setSuccessMsg] = useState('')


    const fixFormat = (_dataBody) => {

        const fixed = {
            signUp: {
                fullName: {
                    firstName: _dataBody.firstName,
                    lastName: _dataBody.lastName,
                },
                phone: _dataBody.phone,
                email: _dataBody.email,
                password: _dataBody.password
            },
            courses: {
                date: _dataBody.date,
                courses:JSON.parse(_dataBody.courses) 
            }
        }

        return fixed;
    };

    const signUpAndBuyCourses = async (body) => {
        
        try {
            const { data } = await apiPost(BuyCoursesForNewOrExistingUser, body);
            setSuccessMsg(data.msg)
        } catch (err) {
            console.log(err)
            alert(err.response);
        }
        // err_msg
    };

    const onSub = (_dataBody) => {
        setSuccessMsg('')
        signUpAndBuyCourses(fixFormat(_dataBody))
    };
    return (
        <div className='d-flex justify-content-center'>
            <AuthAdmin />

            <div className='col-md-4 col-10 mt-5 shadow-lg p-3 rounded-5'>
                <form onSubmit={handleSubmit(onSub)}>
                    <h5 className='display-6' style={{ fontSize: '30px' }}>Buy a Fullstack course for a new or existing user by date</h5>
                    <label>First Name:</label>
                    <input {...register('firstName', { minLength: { value: 2, message: "A minimum of 2 characters is required" }, maxLength: { value: 20, message: 'A maximum of 20 characters is required' }, required: { value: true, message: "This field is required" } })} className='form-control' type="text" />
                    {errors.firstName && errors.firstName.type == 'required' && <small className="text-danger d-block">{errors.firstName.message}</small>}
                    {errors.firstName && errors.firstName.type == 'minLength' && <small className="text-danger d-block">{errors.firstName.message}</small>}
                    {errors.firstName && errors.firstName.type == 'maxLength' && <small className="text-danger d-block">{errors.firstName.message}</small>}

                    <label>First Name:</label>
                    <input {...register('lastName', { minLength: { value: 2, message: "A minimum of 2 characters is required" }, maxLength: { value: 20, message: 'A maximum of 20 characters is required' }, required: { value: true, message: "This field is required" } })} className='form-control' type="text" />
                    {errors.lastName && errors.lastName.type == 'required' && <small className="text-danger d-block">{errors.lastName.message}</small>}
                    {errors.lastName && errors.lastName.type == 'minLength' && <small className="text-danger d-block">{errors.lastName.message}</small>}
                    {errors.lastName && errors.lastName.type == 'maxLength' && <small className="text-danger d-block">{errors.lastName.message}</small>}

                    <label>Phone:</label>
                    <input {...register('phone', { minLength: { value: 7, message: "A minimum of 7 characters is required" }, maxLength: { value: 15, message: 'A maximum of 15 characters is required' }, required: { value: true, message: "This field is required" } })} className='form-control' type="text" />
                    {errors.lastName && errors.phone.type == 'required' && <small className="text-danger d-block">{errors.phone.message}</small>}
                    {errors.phone && errors.phone.type == 'minLength' && <small className="text-danger d-block">{errors.phone.message}</small>}
                    {errors.phone && errors.phone.type == 'maxLength' && <small className="text-danger d-block">{errors.phone.message}</small>}

                    <label>Email:</label>
                    <input {...register('email', { pattern: { value: regEmail, message: 'Invalid email' }, required: { value: true, message: 'This field is required' } })} className='form-control' type="text" />
                    {errors.email && errors.email.type == 'required' && <small className="text-danger d-block">{errors.email.message}</small>}
                    {errors.email && errors.email.type == "pattern" && <small className="text-danger d-block">{errors.email.message}</small>}

                    <label>Password:</label>
                    <input {...register('password', { minLength: { value: 5, message: "A minimum of 5 characters is required" }, maxLength: { value: 20, message: 'A maximum of 20 characters is required' }, required: { value: true, message: "This field is required" } })} className='form-control' type="text" />
                    {errors.password && errors.password.type == 'required' && <small className="text-danger d-block">{errors.password.message}</small>}
                    {errors.password && errors.password.type == 'minLength' && <small className="text-danger d-block">{errors.password.message}</small>}
                    {errors.password && errors.password.type == 'maxLength' && <small className="text-danger d-block">{errors.password.message}</small>}

                    <label>Subscribe Until:</label>
                    <input {...register('date', { required: { value: true, message: "This field is required" } })} className='form-control' type="date" />
                    {errors.date && errors.date.type == 'required' && <small className="text-danger d-block">{errors.date.message}</small>}
                    
                    <select {...register('courses')} className='form-select mt-4'>
                        <option value={"[100]"}>FullStack</option>
                        <option value="[1,2,3,4,5,6,7,8,9,10,11,12]">Frontend</option>
                        <option value="[13,14,15,16]">Backend</option>
                    </select>
                    {successMsg && <small className="text-success d-block">{successMsg}</small>}

                    <button className='btn btn-primary mt-2'>Buy Course to User</button>
                </form>
            </div>

        </div>
    )
}

export default UsersManagement