import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const SubscribesCheck = ({shortId}) => {
    const { user } = useSelector((store) => store.userReducer);
    // let course = user.myLearning.filter(item => item.ShortIdCourse == shortId)
    const nav = useNavigate()
    useEffect(()=>{
        const course = user?.myLearning.find(item=>item.ShortIdCourse == shortId)

        if(!course?.subscribeDateEnd.subscribe){
            nav(-1)
        }

    },[])
  return (
   <></>
  )
}

export default SubscribesCheck