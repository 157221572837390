import { Button, createTheme, Typography } from '@mui/material';
import React, { useRef, useState } from 'react'
import { ThemeProvider } from 'styled-components';
import style from './auth.module.css';
import background from '../../assests/Images/imgHome/strip.png';
import styleForgotPassword from './forgotPass.module.css'
import { useEffect } from 'react';
import { apiPost } from '../../shared/services/services';
import { useForm } from 'react-hook-form';
import { API_URL, REQUEST_RESET_PASSWORD } from '../../shared/constant/url';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
    const { register, getValues, handleSubmit, formState: { errors } } = useForm();
    const regEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    const [error, setError] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const nav = useNavigate()
    const requestForgotPassword = async (body) => {
        setError("")
        setSuccessMsg("")
        const { data } = await apiPost(REQUEST_RESET_PASSWORD, body)
        if (data.status == 'failed') {
            setError("משהו ישתבש או שמשתמש זה אינו קיים במערכת!")
        } else if (data.status == 'Pending') {
            setSuccessMsg("בקשה לאימות סיסמא נשלחה באימייל")
        }
    }

    const onSub = (_dataBody) => {

        const obj = {
            email: _dataBody.email,
            redirectUrl: API_URL+'resetPassword'
        }

        requestForgotPassword(obj)
    }



    return (
        <div className={styleForgotPassword.forgotPassword} >

            <div dir='rtl' className={styleForgotPassword.inputWidthPostion}>
                <form onSubmit={handleSubmit(onSub)}>
                    <Typography variant="h6" color={'white'} className="pt-5">בקשה לאיפוס סיסמא באימייל:</Typography>
                    <input {...register('email', { pattern: regEmail })} style={{ color: 'skyblue' }} placeholder='הקלד אימייל...' type="email" className={`col-12 ${style.formCtrl} ${style.myShadow}`} />
                    {error && <small className="text-danger d-block">{error}</small>}
                    {successMsg && <small className="text-success d-block">{successMsg}</small>}
                    <Button type='submit' color='info' variant="contained" className='mt-2'  >בקשה לאימות סיסמא באימייל</Button>
                    <Button onClick={() => nav(-1)} color='secondary' variant="contained" className='mt-2 me-2'  >חזור</Button>
                </form>
            </div>

        </div>
    )
}

export default ForgotPassword