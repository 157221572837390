import React from 'react'
import style from './footer.module.css'
import facebook from './icons/facebook.png'
import linkdin from './icons/linkdin.png'
import instagram from './icons/instagram.png'
import youtube from './icons/youtube.png'
import smartPhone from './icons/smartphone.png'
import mail from './icons/mail.png'
import location from './icons/location.png'
import syllabus from './icons/syllabus.png'
import adults from './icons/adultsStories.png'
import { useNavigate } from 'react-router-dom'

const Footer = () => {
    const nav = useNavigate()
    return (
        <div>
            <div className={style.socialIcons}>
                <h4 className={style.h4}>עקבו אחרינו</h4>
                <div className={style.row}>
                    <div className={style.iconBox}>
                        <img onClick={() => window.open("https://www.youtube.com/watch?v=57paL7E4Ibo")} className={style.icon} src={youtube} alt="youtube" />
                    </div>
                    <div className={style.iconBox}>
                        <img onClick={() => window.open("https://www.linkedin.com/company/blackopsacademy/")} className={style.icon} src={linkdin} alt="linkdin" />
                    </div>
                    <div className={style.iconBox}>
                        <img onClick={() => window.open("https://www.facebook.com/BlackOpsAcademy")} className={style.icon} src={facebook} alt="facebook" />
                    </div>
                    <div className={style.iconBox}>
                        <img onClick={() => window.open("https://www.instagram.com/blackopsil/?igshid=YmMyMTA2M2Y%3D")} className={style.icon} src={instagram} alt="instagram" />
                    </div>
                </div>
            </div>

            <div className={style.whiteFooter}>
                <div className={style.row + " " + style.center}>
                    <div>
                        <h5 style={{ fontWeight: '800' }} className='m-0 p-0'>יצירת קשר</h5>
                        <div className={'d-flex align-items-center p-0 py-1'} style={{ cursor: 'pointer' }}>
                            <img className='mx-2' src={smartPhone} width={'30px'} alt="smartPhone" />
                            <a className={style.a + ' p-0 m-0'} href="tel:052-6374961" >052-6374961</a>
                        </div>
                        <div className={'d-flex align-items-center p-0 py-1'} style={{ cursor: 'pointer' }}>
                            <img className='mx-2' src={mail} width={'30px'} alt="mail" />
                            <a className={style.a + ' p-0 m-0'} href="mailto:info@blackops.co.il" >info@blackops.co.il</a>
                        </div>
                       

                    </div>
                    <div>
                    <div className={'d-flex align-items-center p-0 py-1'} style={{ cursor: 'pointer' }}>
                            <img className='mx-2' src={location} width={'30px'} alt="location" />
                            <a className={style.a + ' p-0 m-0'} target="_blank" href='https://www.google.com/maps/place/Soncino+St+16,+Tel+Aviv-Yafo,+Israel/@32.0674329,34.7879084,17z/data=!3m1!4b1!4m5!3m4!1s0x151d4b764a913c31:0x82232cf92194b8ef!8m2!3d32.0674329!4d34.7879084' >רחוב שונצינו 16, תל אביב</a>
                        </div>
                     
            
                    </div>
                </div>
            </div>


            <div className={style.blackOpsBlue}>
                <p>BlackOps© 2023</p>
            </div>
        </div>
    )
}

export default Footer