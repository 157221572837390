import {
  Button,
  Container,
  createTheme,
  Typography,
} from "@mui/material";
import style from "./home.module.css";
import { blueGrey, grey, red } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CHECK_SUBSCRIBES,
  GET_MY_LERNING_ROUTE,
} from "../shared/constant/url";
import { apiGet } from "../shared/services/services";
import { useSelector } from "react-redux";
import { SwiperSlideX } from "../shared/components/swiper/swiperSlide";
import { freeCourses } from "../shared/helpers/example";
const custom = createTheme({
  palette: {
    primary: {
      main: grey["400"],
    },
    secondary: {
      main: grey["900"],
    },
    error: {
      main: red["A200"],
    },
    success: {
      main: blueGrey["A700"],
    },
    info: {
      main: grey["A100"],
    },
  },
});
const ariaLabel = { 'aria-label': 'description' };

const Home = () => {
  const mediaQuery = window.matchMedia("(max-width: 768px)");
  const [slider, setSlider] = useState(mediaQuery.matches ? 2 : 4);
  const [myLearning, setMyLearning] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useSelector((store) => store.userReducer);
  const nav = useNavigate();


  //listen to media query js
  useEffect(() => {
    let mediaQuery = window.matchMedia("(min-width: 768px)");
    mediaQuery.addEventListener("change", (e) => {
      if (e.matches) setSlider(4);
      else {
        setSlider(2);
      }
    });
  }, [slider]);

  useEffect(() => {
    if (user) getMyLearning();
  }, [user]);

  //get my learning Courses
  const getMyLearning = async () => {
    const resp = await apiGet(CHECK_SUBSCRIBES);
    const { data } = await apiGet(GET_MY_LERNING_ROUTE);
    setMyLearning(data);
  };






  return (
    <div>
      <div className={style.strip}>
        <h1 className={style.stripTxt}>BlackOps הדרך שלך להייטק </h1>
      </div>
      <Container maxWidth={'lg'}>
        <div className={style.link} dir="rtl">
        <a target="_blank"  href="https://www.blackops.co.il/online">לרכישה לחץ כאן</a>
        </div>
        {user?.myLearning?.length > 0 && (
          <div className={"pt-5 py-md-5 " + style.showHideSlider}>
            <hr />
            <div className="d-flex justify-content-between">
              <Button
                onClick={() => nav("/myLearning")}
                variant="text"
                color="success"
              >
                למעבר ללימודים שלי
              </Button>
              <Typography variant="h5" gutterBottom>
                הלימודים שלי
              </Typography>
            </div>
            <div className={style.swiperSlideX + " h-100"}>
              <SwiperSlideX data={myLearning} slider={slider} />
            </div>

       
          </div>

        )}
        {user?.myLearning?.length > 0 &&  <hr />}    
        {freeCourses?.map((item, i) => (
          <div dir="rtl" key={i}>
            <h3 className="my-3">{item.seasonTitle}</h3>
            <div className="row row-cols-1 row-cols-md-3 g-2 row-cols-lg-4  h-100">
              {item?.episodes.map((episode, i) => (
            <div className="col my-3 mt-md-0 h-100">
            <p className="m-0 p-0">{episode.title}</p>
            <div className="pt-0 ps-3" style={{borderRadius:'20px'}}>
              <iframe style={{borderRadius:'20px'}} width="100%" height="250px" src={episode.videoUrl} title="HEAVY SWELL SLAMS THE NORTH SHORE BEFORE THE PIPE MASTERS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen="allowfullscreen"></iframe>
            </div>
          </div>

              ))}
            </div>


          </div>
        ))}
     
        <div className={style.logo}>
        </div>
        <div className={style.link} dir="rtl">
        <a target="_blank"  href="https://www.blackops.co.il/online">לרכישה לחץ כאן</a>
        </div>   
           </Container>
    </div>

  )
}
export default Home;


