import { Button, Typography } from '@mui/material';
import React, { useState } from 'react'
import styleForgotPassword from './forgotPass.module.css'
import style from './auth.module.css';
import { useEffect } from 'react';
import { apiPost } from '../../shared/services/services';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { RESET_PASSWORD } from '../../shared/constant/url';

const ForgotPassword = () => {
    const { register, getValues, handleSubmit, formState: { errors } } = useForm();
    const [eye, setEye] = useState("password");
    const [querys] = useSearchParams();
    const [successMsg,setSuccessMsg] = useState("")
    const [error,setError] = useState("")
    const nav = useNavigate()
    const resetPassword = async (body) => {
        try{
            setSuccessMsg("")
            setError("")
            const {data} = await apiPost(RESET_PASSWORD,body);
            console.log(data)
            if(data.status == "Success"){
                setSuccessMsg("שינוי הסיסמא עבר בהצלחה,מעביר לעמוד התחברות...")
                setTimeout(()=>{nav('/login')},4000)
            }
        }catch(err){
            console.log(err.response)
            setError("משהו ישתבש נסה בקשה לשחזור סיסמא שוב")
            setTimeout(()=>{
              if(window.confirm('האם ברצונך לעבור לעמוד שיחזור סיסמא')){
                nav('/forgotPassword')
              }  
            },1000)
        }
    
    }

    const onSub = (_dataBody) => {
   
      const obj=  {
            "userId" : querys.get('id'),
            "resetString" : querys.get('str'),
            "newPassword" : _dataBody.password 
        }
        console.log(obj)
        resetPassword(obj)


    }



    
    return (
        <div className={styleForgotPassword.forgotPassword} >

            <div dir='rtl' className={styleForgotPassword.inputWidthPostion}>
                <form onSubmit={handleSubmit(onSub)}>
                    <Typography variant="h6" color={'white'} className="pt-5">סיסמא:</Typography>


                    <div dir='ltr' className={`d-flex ${style.myShadow} ${style.formCtrl} ${styleForgotPassword.formCtrlPassoword} align-items-center`}>
                        <input {...register('password',{minLength:{value:5,message:"סיסמא מינימום 5 תווים"},required:{value:true,message:'שדה זה חובה'}})} type={eye} className={`col-11 ${style.formPassword}`} />
                        {eye === 'password' ?
                            <FaEyeSlash style={{ cursor: 'pointer' }} onClick={() => setEye('text')} className='me-auto ms-2 ' fontSize={'20px'} /> :
                            <FaEye style={{ cursor: 'pointer' }} onClick={() => setEye('password')} className='me-auto ms-2' fontSize={'20px'} />
                        }
                    </div>
                    {errors.password && errors.password.type == "required" && <small className="text-danger d-block">{errors.password.message}</small>}
                    {errors.password && errors.password.type == "minLength" && <small className="text-danger d-block">{errors.password.message}</small>}

                    <Typography variant="h6" color={'white'} className="pt-2">אימות סיסמא:</Typography>

                    <div dir='ltr' className={`d-flex ${style.myShadow} ${style.formCtrl} ${styleForgotPassword.formCtrlPassoword} align-items-center`}>
                        <input {...register('validPass',{required:{value:true,message:'אימות סיסמא חובה'},validate: { validate:( (value) => value === getValues('password')) }})} type={eye} className={`col-11 ${style.formPassword}`} />
                        {eye === 'password' ?
                            <FaEyeSlash style={{ cursor: 'pointer' }} onClick={() => setEye('text')} className='me-auto ms-2 ' fontSize={'20px'} /> :
                            <FaEye style={{ cursor: 'pointer' }} onClick={() => setEye('password')} className='me-auto ms-2' fontSize={'20px'} />
                        }
                    </div>
                    {errors.validPass && errors.validPass.type == 'validate' && <small className='error d-block text-danger'>סיסמאות אינן תואמות</small>}
                    {errors.validPass && errors.validPass.type == "required" && <small className="text-danger d-block">{errors.validPass.message}</small>}
                    {error &&  <small className="text-danger d-block">{error}</small>}
                    {successMsg &&  <small className="text-success d-block">{successMsg}</small>}

                    <Button type='submit' color='info' variant="contained" className='mt-2'  >שחזור סיסמא</Button>
                    <Button onClick={() => nav(-1)} color='secondary' variant="contained" className='mt-2 me-2'  >חזור</Button>
                </form>
            </div>

        </div>
    )
}

export default ForgotPassword