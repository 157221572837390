import React from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { TOKEN_KEY } from '../../constant/string'
import { CHECK_TOKEN_ROUTE } from '../../constant/url'
import { logout } from '../../redux/features/userSlice'
import { apiGet, apiPost } from '../../services/services'

const AuthUser = () => {
    const nav = useNavigate()
    const dispatch = useDispatch();

    useEffect(()=>{
        checkToken()
    },[])

    const checkToken = async() =>{
        const {data} = await apiPost(CHECK_TOKEN_ROUTE,{token:localStorage[TOKEN_KEY]})
        if(!data.status){
            localStorage.removeItem('token')
            dispatch(logout())
            nav('/login')
        }
    }

  return (
   <>
   </>
  )
}

export default AuthUser