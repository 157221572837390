export const freeCourses = [{
        seasonTitle: 'קורס HTML בסיס - הכרות - עונה 1',
        episodes: [{
                title: 'HTML - שפת התגיות הכרות',
                videoUrl: 'https://www.youtube.com/embed/UcJiaVk0nK4?list=PLTiiQwMFlFmVVJE0Pn3U1SRqcilNV95bZ'
            },
            {
                title: ' VISUAL STUDIO CODE',
                videoUrl: 'https://www.youtube.com/embed/a1lLz7yaV-k?list=PLTiiQwMFlFmVVJE0Pn3U1SRqcilNV95bZ'
            },
            {
                title: 'תגיות בסיס ותבנית בסיס בדף HTML',
                videoUrl: 'https://www.youtube.com/embed/dEbWtmMN3dM?list=PLTiiQwMFlFmVVJE0Pn3U1SRqcilNV95bZ'
            },
            {
                title: 'תגיות INLINE והוספת לינקים/קישורים',
                videoUrl: 'https://www.youtube.com/embed/2W6TnDFuneg?list=PLTiiQwMFlFmVVJE0Pn3U1SRqcilNV95bZ'
            }
        ]
    },
    {
        seasonTitle: 'קורס CSS3 + HTML5 מתקדם - עונה 1+',
        episodes: [{
                title: 'תגיות HTML5 חדשות',
                videoUrl: 'https://www.youtube.com/embed/kvoJxJq_XIw?list=PLTiiQwMFlFmWwyVXA5S91o3gRoHPzGANg'
            },
            {
                title: 'עבודה עם FLEXBOX - בסיס',
                videoUrl: 'https://www.youtube.com/embed/kOmyw21oTqc?list=PLTiiQwMFlFmWwyVXA5S91o3gRoHPzGANg'
            },
            {
                title: 'מייצרים שבלונה עם פלקס  חלק א',
                videoUrl: 'https://www.youtube.com/embed/P1IVDls9YF0?list=PLTiiQwMFlFmWwyVXA5S91o3gRoHPzGANg'
            },
            {
                title: 'מייצרים שבלונה עם פלקס  - חלק ב',
                videoUrl: 'https://www.youtube.com/embed/7pN2ODFo3xY?list=PLTiiQwMFlFmWwyVXA5S91o3gRoHPzGANg'
            }
        ]
    },
    {
        seasonTitle: 'קורס BOOTSTRAP 5 - מלא - עונה 2',
        episodes: [{
                title: 'מה היא ספריית BOOTSTRAP',
                videoUrl: 'https://www.youtube.com/embed/zPywEWWVgxU?list=PLTiiQwMFlFmUfDEJKism2Hva410BKSdQ-'
            },
            {
                title: 'צבעים , טקסטים ונקודות שבירה',
                videoUrl: 'https://www.youtube.com/embed/zzD-0jYa4Rw?list=PLTiiQwMFlFmUfDEJKism2Hva410BKSdQ-'
            },
            {
                title: 'גריד ונקודות שבירה מתקדם',
                videoUrl: 'https://www.youtube.com/embed/NoEsBbtE6kg?list=PLTiiQwMFlFmUfDEJKism2Hva410BKSdQ-'
            },
            {
                title: 'MARGIN ו PADDING רווחים ב BS ',
                videoUrl: 'https://www.youtube.com/embed/uIdfVyvVtDo?list=PLTiiQwMFlFmUfDEJKism2Hva410BKSdQ-'
            }
        ]
    },
    {
        seasonTitle: 'קורס JS בסיס - עונה 3',
        episodes: [{
                title: 'JS - מבינים יכולות הבסיס של השפה',
                videoUrl: 'https://www.youtube.com/embed/au-IDzJCy_M?list=PLTiiQwMFlFmXbhcyMUbPWTP1ZXE_PGdGO'
            },
            {
                title: ' תכנות ב JS  והאזנה לאירוע טעינה',
                videoUrl: 'https://www.youtube.com/embed/w77ao7qF7ZY?list=PLTiiQwMFlFmXbhcyMUbPWTP1ZXE_PGdGO'
            },
            {
                title: 'מה הוא ה DOM והחוקים החשובים שלו',
                videoUrl: 'https://www.youtube.com/embed/poaYXM0FFjM?list=PLTiiQwMFlFmXbhcyMUbPWTP1ZXE_PGdGO'
            },
            {
                title: 'מה היא פונקציה  ב JS - בסיס',
                videoUrl: 'https://www.youtube.com/embed/K3pQWLjrfXs?list=PLTiiQwMFlFmXbhcyMUbPWTP1ZXE_PGdGO'
            }
        ]
    },

]