import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { logout } from '../../redux/features/userSlice'
import { useSelector, useDispatch } from "react-redux";
import { apiGet } from '../../services/services';
import { LOGOUT_ROUTE } from '../../constant/url';

const Logout = () => {
    const dispatch = useDispatch();
    
    const nav = useNavigate()
    useEffect(()=>{
      disconnected()
    },[])

    const disconnected =async () =>{
      const {data} =await apiGet(LOGOUT_ROUTE)
    
      if(data.status){
        localStorage.removeItem('token')
        dispatch(logout())
        nav('/')
      }
    }

    
  return (
  <></>
  )
}

export default Logout